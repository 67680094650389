















































































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import dayjs from 'dayjs'

import Account from '@/models/account'

import Listbox from 'primevue/listbox'
import Toolbar from 'primevue/toolbar'
import AppAccountTransactionsTable from '@/components/AppAccountTransactionsTable'

Vue.component('Listbox', Listbox)
Vue.component('Toolbar', Toolbar)
Vue.component('AppAccountTransactionsTable', AppAccountTransactionsTable)

const Auth = namespace('auth')

@Component
export default class Forecast extends Vue {
  @Auth.Getter currentPermissions!: string

  accounts: Account[] = []
  accountParam: (string | null)[] = [null]
  pendingTransactionStatusParam: string[] = ['2']
  forecastedTransactionStatusParam: string[] = ['1']
  transactionDateParam: (string | Date | null)[] | null = [
    null,
    new Date(dayjs().add(1, 'month').format('YYYY-MM-DD'))
  ]

  finalForecastedBalance: number | null = null

  enableReadAccount = false
  enableReadAccountTransaction = false

  hiddenColumns = [
    'recurring',
    'accountName',
    'transactionCategory',
    'isEstimate',
    'equity',
    'transactionStatusName',
    'draftType',
    'isContribOrDistrib',
    'notes',
    'actionButtons'
  ]

  showClosedAccounts = false
  accountSidebarItems: Account[] = []

  get filteredAccounts () {
    return this.accountSidebarItems.filter(item => {
      return this.showClosedAccounts || (!this.showClosedAccounts && item.active)
    })
  }

  get selectedAccounts () {
    const accounts = []
    const relevantAccounts = (this.accountParam && !(this.accountParam.length === 1 && this.accountParam[0] === null))
      ? this.accountParam
      : this.accounts.map(item => item.id).filter(item => { return item !== null })
    for (let i = 0; i < relevantAccounts.length; i++) {
      const account = this.accounts.filter(item => {
        return String(item.id) === String(relevantAccounts[i])
      })[0]
      if (account) {
        accounts.push(account)
      }
    }
    return accounts
  }

  get totalClearedBalance () {
    let total = 0.0
    for (let i = 0; i < this.selectedAccounts.length; i++) {
      const account = this.selectedAccounts[i]
      if (account) {
        total += Number(account.clearedBalance)
      }
    }
    return total
  }

  get totalWorkingBalance () {
    let total = 0.0
    for (let i = 0; i < this.selectedAccounts.length; i++) {
      const account = this.selectedAccounts[i]
      if (account) {
        total += Number(account.clearedBalance) + Number(account.pendingBalance)
      }
    }
    return total
  }

  mounted () {
    this.getCurrentPermissions()
    this.getAccounts()
  }

  getCurrentPermissions () {
    const permissions = this.currentPermissions
    if (!permissions) return

    this.enableReadAccount = permissions.includes('read_account')
  }

  async getAccounts () {
    if (!this.enableReadAccount) return

    this.accounts = (await Account
      .includes('defaultInvestment')
      .order({ active: 'desc' })
      .order('name')
      .per(1000)
      .all()
    ).data

    this.accounts = [
      new Account({ id: null, name: 'All Accounts', active: 1 })
    ].concat(this.accounts.map(item => { return item }))

    this.accountSidebarItems = this.insertActiveExitedSeparator(JSON.parse(JSON.stringify(this.accounts)))
  }

  toggleClosedAccounts () {
    this.showClosedAccounts = !this.showClosedAccounts
  }

  insertActiveExitedSeparator (accounts: Account[]) {
    let idx = -1
    for (let i = 0; i < accounts.length; i++) {
      const account = accounts[i]
      if (!account.active) {
        idx = i
        break
      }
    }
    if (idx >= 0) {
      accounts.splice(idx, 0, new Account({
        name: 'Closed Accounts',
        active: true
      }))
    }
    return accounts
  }

  onChangeSelectedAccounts (event: any) {
    const userClickedAllAccounts = event.originalEvent.target.innerText === 'All Accounts'
    if (this.accountParam.includes(null) && this.accountParam.length === 2) {
      if (userClickedAllAccounts) {
        this.accountParam = [null]
      } else {
        this.accountParam.splice(this.accountParam.indexOf(null), 1)
      }
    } else if (this.accountParam.includes(null) || this.accountParam.length === 0) {
      this.accountParam = [null]
    }
  }

  onRunningBalancesComputed (finalBalance: number) {
    this.finalForecastedBalance = finalBalance
  }
}
